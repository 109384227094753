<div class="container-fluid likehostel-screen pb-5">
    <div class="row">
        <div class="col-12 offset-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <h1>
                <span>Like Hostel - </span>
                <span>Свобода и комфорт нового поколения</span>
            </h1>
            <h2>Любимая сеть хостелов в России и СНГ от 300₽ в сутки в более 50 городах!</h2>
        </div>
    </div>
    <div class="row mt-3 mt-md-5">
        <div class="col-12 offset-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <city-search [focus]="true"></city-search>
        </div>
    </div>
    <div class="row mt-3 mt-md-5">
        <div class="col-12 offset-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <popular-cities></popular-cities>
        </div>
    </div>
</div>

<div class="container-fluid mt-4 mt-md-5 likehostel-description">
    <a name="about" class="navbar-skipped-anchor"></a>
    <div class="row text-center">
        <div class="col-12 offset-0 col-md-10 offset-md-1 col-lg-6 offset-lg-3">
            <h3 class="mb-3">Комфортные, уютные, красивые, безопасные гостиницы в городах РФ и СНГ</h3>
            <p>
                Like Hostel - это идеальное место для размещения больших и маленьких компаний, семейных пар с детьми.
                У нас комфортные кровати, оборудованные кухни, прачечные и ванные комнаты.
                Мы гордимся идеальной чистотой номеров и европейским сервисом!
            </p>
        </div>
    </div>
</div>

<div #photosSlick class="photos-slick mt-3 mt-md-5 d-none">
    <div *ngFor="let photo of photos">
        <img src="{{ photo }}" class="img-spinner">
    </div>
</div>

<div class="container mt-3 mt-md-5 likehostel-stat">
    <div class="row">
        <div class="col-6 col-md-3">
            <span class="item">9000+</span>
            <span class="value">гостей заселяется ежемесячно</span>
        </div>
        <div class="col-6 col-md-3">
            <span class="item">300+</span>
            <span class="value">гостей заселяется ежедневно</span>
        </div>
        <div class="col-6 col-md-3">
            <span class="item">98%</span>
            <span class="value">клиентов полностью удовлетворены обслуживанием</span>
        </div>
        <div class="col-6 col-md-3">
            <span class="item">50+</span>
            <span class="value">хостелов открыто в странах СНГ</span>
        </div>
    </div>
</div>

<hostel-whats-in [title]="'Что в хостелах?'"></hostel-whats-in>

<div class="container">
    <h3 class="text-center">Более 50 хостелов в странах СНГ</h3>
    <ul class="likehostel-countries mt-3 mb-4">
        <li class="flag-russia">Россия</li>
        <li class="flag-belorussia">Белоруссия</li>
        <li class="flag-ukraine">Украина</li>
        <li class="flag-kazakhstan">Казахстан</li>
        <li class="flag-turkmenistan">Туркменистан</li>
    </ul>
    <cities-list></cities-list>
</div>

<div class="mt-5 mb-5">
    <div class="franshiza-circle">
        <div class="franshiza-wrapper">
            <h3>Вы можете зарабатывать</h3>
            <h4>от 100 000 рублей в месяц</h4>
            <a href="http://likehostels.ru/franshiza/?source=site" class="btn btn-like btn-lg">Узнать как</a>
        </div>
    </div>
</div>
