import { Component, Input } from "@angular/core";
import { Vacancy } from "./vacancy";
import './vacancy-mini.component.scss';

@Component({
    selector: 'vacancy-mini',
    templateUrl: './vacancy-mini.component.html'
})
export class VacancyMiniComponent {
    @Input('vacancy')
    public vacancy: Vacancy;
}
