import { Component, OnInit } from "@angular/core";
import './navbar.scss';
import { NavbarService } from "./navbar.service";
import { NavigationStart, Router } from "@angular/router";

@Component({
    selector: 'likehostel-navbar',
    templateUrl: './likehostel-navbar.component.html'
})
export class LikehostelNavbarComponent implements OnInit {
    public showLeftMenu: boolean = false;

    public constructor(
        public navbarService: NavbarService,
        public router: Router
    ) {
    }

    public ngOnInit(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.showLeftMenu = false;
            }
        });
    }

    public toggleLeftMenu(): void {
        this.showLeftMenu = !this.showLeftMenu;
    }

}
