<nav>
    <div class="container">
        <div class="likehostel-logo-navbar">
            <a *ngIf="!navbarService.useAbsoluteLinks" routerLink="/" class="likehostel-logo"></a>
            <a *ngIf="navbarService.useAbsoluteLinks" href="https://likehostels.ru" class="likehostel-logo"></a>
        </div>
        <ul [class.active]="showLeftMenu">
            <li><a routerLink="/" fragment="about">О хостеле</a></li>
            <li><a href="https://likehostels.ru/franshiza/?source=site_head">Франшиза</a></li>
            <li><a href="https://likehostels.ru/visa">Визы</a></li>
            <li><a routerLink="/job">Вакансии</a></li>
            <li></li>
        </ul>
        <div class="float-right">
            <button class="navbar-toggler d-lg-none" (click)="toggleLeftMenu()">
                <i class="fas fa-bars"></i>
            </button>
        </div>
    </div>
</nav>
<div class="navbar-overlay"></div>
<div class="navbar-menu-background"
     [class.active]="showLeftMenu"
     (click)="toggleLeftMenu()"></div>
