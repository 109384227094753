import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { IndexComponent, IndexResolver } from "./index/index.component";
import { VisaComponent } from "./visa/visa.component";

const routes: Routes = [
    {
        path: '',
        component: IndexComponent, resolve: {
            component: IndexResolver
        }
    },
    {
        path: 'visa',
        component: VisaComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    providers: [IndexResolver],
    exports: [RouterModule]
})
export class LikeHostelRoutingModule {
}
