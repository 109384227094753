<div class="container mt-5">
    <h1 class="text-center">
        Вакансии компании "Лайк Хостел"
    </h1>
</div>
<markdown-render class="d-block container mt-3 mb-3" [content]="companyDescription"></markdown-render>

<div class="container mb-5">
    <h1 *ngIf="vacancies.length > 0">Вакансии компании</h1>
    <hr *ngIf="vacancies.length > 0">
    <div *ngFor="let vacancy of vacancies">
        <vacancy-mini [vacancy]="vacancy"></vacancy-mini>
    </div>
    <h1 *ngIf="vacancies.length == 0" class="text-center">
        Открытых вакансий нет
    </h1>
</div>
