<div class="container mt-3 mb-5">
    <h1>{{ vacancy.title }}</h1>
    <h2>от 50 000 до 100 000 руб.</h2>
    <div><a [routerLink]="['/job/company', vacancy.company.companyId]" class="vacancy-company">{{ vacancy.company.name }}</a></div>
    <div>
        <i class="fas fa-map-marker-alt mr-2"></i>
        <a [routerLink]="['/job/city/msk']">Москва</a>, м. Выставочная, Пресненская набережная, 12
    </div>
    <div class="mt-3">Требуемый опыт работы: 1–3 года</div>
    <div>Полная занятость, гибкий график</div>
    <hr>
    <markdown-render class="d-block mt-3" [content]="vacancy.description"></markdown-render>
    <hr>
    <h2>Отклик на вакансию</h2>
    <form *ngIf="!showResponseMsg" [formGroup]="responseForm" (ngSubmit)="onResponseSubmit()">
        <div class="form-group">
            <label for="vacancy-response-name">Имя и фамилия</label>
            <input type="text" class="form-control" id="vacancy-response-name" formControlName="name">
        </div>
        <div class="form-group">
            <label for="vacancy-response-phone">Телефон</label>
            <input type="text" class="form-control" id="vacancy-response-phone" formControlName="phone">
        </div>
        <div class="form-group">
            <label for="vacancy-response-email">E-mail</label>
            <input type="email" class="form-control" id="vacancy-response-email" formControlName="email">
        </div>
        <div class="form-group">
            <label for="vacancy-response-covering-letter">Сопроводительное письмо</label>
            <textarea formControlName="coveringLetter" class="form-control" rows="5" id="vacancy-response-covering-letter"></textarea>
        </div>
        <button type="submit" class="btn btn-like" [disabled]="!responseForm.valid">Отправить отклик</button>
    </form>
    <div *ngIf="showResponseMsg">
        <p><b>Благодарим за интерес к нашей компании!</b></p>
        <p>В ближайшее время мы свяжемся с Вами для приглашения на собеседование.</p>
    </div>
</div>
