import {
    AfterViewInit, Component, ElementRef, Inject, Injectable, NgZone, OnInit, PLATFORM_ID,
    ViewChild
} from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import './index.component.scss';
import { FooterService } from "layout/footer/footer.service";
import { GlobalConfig } from "app/bootstrap/global-config";
import { isPlatformBrowser } from "@angular/common";
import { TransferStateService } from "app/bootstrap/transfer-state.service";
import { MetaService } from "app/bootstrap/meta.service";
import { Title } from "@angular/platform-browser";

export interface IndexData {
    photos: string[];
}

@Injectable()
export class IndexResolver implements Resolve<IndexData> {

    protected readonly TRANSFER_STATE_KEY = 'LikeHostelIndexResolverData';

    public constructor(
        protected httpClient: HttpClient,
        protected transfaerStateService: TransferStateService
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IndexData> {
        return this.transfaerStateService.get<IndexData>(this.TRANSFER_STATE_KEY, () => {
            return this.httpClient.get<IndexData>('/v1/likehostel/index').toPromise();
        });
    }

}

@Component({
    selector: 'likehostel-index',
    templateUrl: './index.component.html'
})
export class IndexComponent implements OnInit, AfterViewInit {

    public photos: string[] = [];

    @ViewChild('photosSlick')
    public photosSlick: ElementRef;

    public constructor(
        @Inject(PLATFORM_ID) protected readonly platformId: Object,
        protected globalConfig: GlobalConfig,
        protected footerService: FooterService,
        protected route: ActivatedRoute,
        protected ngZone: NgZone,
        protected metaService: MetaService,
        protected title: Title
    ) {
    }

    public ngOnInit(): void {
        this.footerService.showCitiesList = false;
        this.title.setTitle('Like Hostel - Свобода и комфорт нового поколения');
        this.metaService.setDescription('Любимая сеть хостелов в России и СНГ от 300₽ в сутки в более 50 городах! Европейский сервис и чистота. Xbox, Wi-Fi. Скидки при длительном проживании и группам!');
        this.route.data.subscribe((data) => {
            this.photos = data.component.photos.map((value: string) => {
                return this.globalConfig.content.url + value;
            });
        });
    }

    public ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            let photosSlick = jQuery(this.photosSlick.nativeElement);
            this.ngZone.runOutsideAngular(() => {
                photosSlick.slick({
                    dots: false,
                    arrows: true,
                    variableWidth: true,
                    autoplay: true,
                    autoplaySpeed: 2000
                });
            });
            photosSlick.removeClass('d-none');
        }
    }

}
