import { NgModule } from "@angular/core";
import { IndexComponent } from "./index/index.component";
import { CommonModule } from "@angular/common";
import { HostelCommonModule } from "hostel/hostel-common.module";
import { CityCommonModule } from "city/city-common.module";
import { LikeHostelRoutingModule } from "./likehostel-routing.module";
import { VisaComponent } from "./visa/visa.component";
import { DocumentsModule } from "documents/documents.module";

@NgModule({
    imports: [
        CommonModule,
        LikeHostelRoutingModule,
        CityCommonModule,
        HostelCommonModule,
        DocumentsModule
    ],
    declarations: [
        IndexComponent, VisaComponent
    ],
    exports: [
        LikeHostelRoutingModule
    ]
})
export class LikeHostelModule {
}
