import { enableProdMode, getModuleFactory, NgZone } from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';
import './likehostel.browser.module.ngfactory';
import { GlobalConfig } from "app/bootstrap/global-config";
import { hideLoadingSpinner } from "app/loading-spinner";
import { BootstrapData } from "app/bootstrap/bootstrap.service";
import * as Raven from 'raven-js';
import { errorHandler } from "app/error/error";
import 'app/vendor.browser';

export function startLikeHostelAppLikeHostel(): void {
    if (APP_CONFIG.browser.sentry.enabled) {
        Raven.setTagsContext({app: 'likehostel'});
    }
    if (APP_MODE == 'production') {
        enableProdMode();
    }

    let ngZone = new NgZone({});
    ngZone.onError.subscribe((error: any) => {
        errorHandler(error);
    });
    platformBrowser([
        {provide: 'http_hostname', useValue: window.document.location.hostname},
        {provide: GlobalConfig, useValue: new GlobalConfig()}
    ]).bootstrapModuleFactory(getModuleFactory('likehostel-browser'), {
        ngZone: ngZone
    }).then((moduleRef) => {
        hideLoadingSpinner();
        console.info(
            'Started "likehostel" app v' + APP_VERSION + '.',
            'WebAPI v' + moduleRef.injector.get(BootstrapData).version
        );
    }).catch((error) => {
        errorHandler(error);
    });
}
