import { ApplicationRef, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { LikeHostelComponent } from "./likehostel.component";
import { LikeHostelRoutingModule } from "./likehostel-routing.module";
import { Error404Module } from "error404/error404.module";
import { BootstrapModule } from "app/bootstrap/bootstrap.module";
import { BootstrapService } from "app/bootstrap/bootstrap.service";
import { LikeHostelModule } from "likehostel/likehostel.module";
import { LayoutModule } from "layout/layout.module";
import { AnalyticsModule } from "analytics/analytics.module";
import { FeedbackModule } from "feedback/feedback.module";
import { VacancyLikeHostelModule } from "vacancy/vacancy.module";

@NgModule({
    id: 'likehostel-browser',
    imports: [
        BrowserModule.withServerTransition({appId: 'likehostel'}),
        BrowserTransferStateModule,
        CommonModule,
        BootstrapModule, LayoutModule, AnalyticsModule, FeedbackModule,
        LikeHostelRoutingModule,
        LikeHostelModule,
        VacancyLikeHostelModule,
        Error404Module
    ],
    providers: [
        BootstrapService,
        {provide: 'bootstrap-service', useExisting: BootstrapService}
    ],
    declarations: [
        LikeHostelComponent
    ],
    entryComponents: [
        LikeHostelComponent
    ]
})
export class LikeHostelBrowserModule {
    public constructor(
        protected bootstrapService: BootstrapService
    ) {
    }

    public ngDoBootstrap(app: ApplicationRef) {
        app.bootstrap(LikeHostelComponent);
    }
}
