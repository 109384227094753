import { Component, Inject, Input, NgZone, PLATFORM_ID } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Converter } from 'showdown';

@Component({
    selector: 'markdown-render',
    template: '<div [innerHtml]="safeHtml"></div>'
})
export class MarkdownRenderComponent {

    private _content: string = '';
    private _converter: Converter | null = null;
    private _safeHtml: SafeHtml | null = null;

    public get safeHtml(): SafeHtml | null {
        return this._safeHtml;
    }

    protected setSafeHtml(safeHtml: SafeHtml): void {
        this._safeHtml = safeHtml;
    }

    protected clearSafeHtml(): void {
        this._safeHtml = null;
    }

    @Input('content')
    public set content(content: string) {
        this.clearSafeHtml();
        this._content = content;
        this.ngZone.runOutsideAngular(() => {
            this.setSafeHtml(this.domSanitizer.bypassSecurityTrustHtml(this.getConverter().makeHtml(content)));
        });
    }

    public get content(): string {
        return this._content;
    }

    public constructor(
        @Inject(PLATFORM_ID) protected readonly platformId: Object,
        protected ngZone: NgZone,
        protected domSanitizer: DomSanitizer
    ) {
    }

    protected getConverter(): Converter {
        if (!this._converter) {
            this._converter = new Converter({
                tables: true,
                strikethrough: true
            });
        }
        return this._converter;
    }

}
