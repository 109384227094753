<div class="row">
    <div class="col-4">
        <p><a [routerLink]="['/job/vacancy', vacancy.vacancyId]">{{ vacancy.title }}</a></p>
        <p *ngIf="!vacancy.hotel">{{ vacancy.company.name }}</p>
        <p *ngIf="vacancy.hotel">{{ vacancy.hotel.name }}</p>
    </div>
    <div class="col-4 text-right">
        от 50 000 до 100 000 руб.
    </div>
    <div class="col-4">
        {{ vacancy.city.name }}
    </div>
</div>
<hr>
